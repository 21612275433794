/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import ost1 from '../../assets/music/ost-1.jpg';
import ost2 from '../../assets/music/ost-2.jpg';
import ost3 from '../../assets/music/ost-3.jpg';
import ost4 from '../../assets/music/ost-4.jpg';
import ost5 from '../../assets/music/ost-5.jpg';
import ost6 from '../../assets/music/ost-6.jpg';
import ost7 from '../../assets/music/ost-7.jpeg';

import albumSonnetImg from '../../assets/music/discography/ellik/2008-album-sonnet.png';
import ostPastaImg from '../../assets/music/discography/ellik/2010-ost-pasta.jpeg';
import ostGoodDayWithTheWindImg from '../../assets/music/discography/ellik/2010-ost-good_day_with_the_wind.jpeg';
import ostInvocationOfLoveImg from '../../assets/music/discography/ellik/2014-ost-invocation_of_love.jpeg';
import ostThePiedPiperOfHamelinImg from '../../assets/music/discography/ellik/2016-ost-the_pied_piper_of_hamelin.jpeg';
import albumHerGardenImg from '../../assets/music/discography/ellik/2016-album-her_garden.png';
import ostJealousyIncarnateImg from '../../assets/music/discography/ellik/2016-ost-jealousy_incarnate.jpg';
import albumOnChristmasDayImg from '../../assets/music/discography/ellik/2016-album-on_christmas_day.png';
import ostGoldenSlumberImg from '../../assets/music/discography/ellik/2018-ost-golden_slumber.jpg';
import ostWatcherImg from '../../assets/music/discography/ellik/2019-ost-watcher.jpg';
import albumLoveCollageImg from '../../assets/music/discography/ellik/2020-album-love_collage.png';

import ellikVideoThumb1 from '../../assets/music/discography/ellik/video-thumb-dawn.png';
import ellikVideoThumb2 from '../../assets/music/discography/ellik/video-thumb-new_life.png';
import ellikVideoThumb3 from '../../assets/music/discography/ellik/video-thumb-the_shadow_of_your_smile.png';

import album1 from '../../assets/music/album-1.jpg';
import album2 from '../../assets/music/album-2.jpg';
import album3 from '../../assets/music/album-3.jpg';
import album4 from '../../assets/music/album-4.jpg';
import album5 from '../../assets/music/album-5.jpg';
import PageTitle from '../../components/PageTitle';
import WhiteSquare from '../../components/WhiteSquare';

import CONFIG from '../../config';

interface SinceBlockType {
  img: any,
  title: string,
  albumId: string,
}

interface SinceBlock1ListType {
  albumSonnet: SinceBlockType;
  ostPasta: SinceBlockType;
  ostGoodDayWithTheWind: SinceBlockType;
  ostInvocationOfLove: SinceBlockType;
  ostThePiedPiperOfHamelin: SinceBlockType;
  albumHerGarden: SinceBlockType;
  ostJealousyIncarnate: SinceBlockType;
  albumOnChristmasDay: SinceBlockType;
  ostGoldenSlumber: SinceBlockType;
  ostWatcher: SinceBlockType;
  albumLoveCollage: SinceBlockType;
}

const melonUrlPrefix = 'https://www.melon.com/album/detail.htm?albumId=';

const sinceBlock1List: SinceBlock1ListType = {
  albumSonnet: {img: albumSonnetImg, title: '05월 정규 1집 [Sonnet]', albumId: '379439'},
  ostPasta: {img: ostPastaImg, title: '02월 MBC 월화 드라마 <파스타> OST 참여', albumId: '795652'},
  ostGoodDayWithTheWind: {img: ostGoodDayWithTheWindImg, title: '05월 KBS 일일 드라마 <바람불어 좋은 날> OST 참여', albumId: '906651'},
  ostInvocationOfLove: {img: ostInvocationOfLoveImg, title: '12월 한중 합작영화 <연애의 발동> OST 참여', albumId: '2293637'},
  ostThePiedPiperOfHamelin: {img: ostThePiedPiperOfHamelinImg, title: '03월 tvN 드라마 <피리부는 사나이> OST 참여', albumId: '2675952'},
  albumHerGarden: {img: albumHerGardenImg, title: '06월 [그녀의 정원 Complete Album] 발매', albumId: '2693356'},
  ostJealousyIncarnate: {img: ostJealousyIncarnateImg, title: '06월 SBS 수목드라마 <질투의 화신> OST 참여', albumId: '10014143'},
  albumOnChristmasDay: {img: albumOnChristmasDayImg, title: '12월 [On Christmas Day] 발매', albumId: '10023746'},
  ostGoldenSlumber: {img: ostGoldenSlumberImg, title: '02월 영화 <골든슬럼버> OST 참여', albumId: '10139013'},
  ostWatcher: {img: ostWatcherImg, title: '08월 OCN 오리지널 <왓쳐> OST 참여', albumId: '10321261'},
  albumLoveCollage: {img: albumLoveCollageImg, title: '01월 [Love Collage] 발매', albumId: '10370906'},
};

/**
 * 모바일용 포인트 이미지 표시 엘리먼트 컴포넌트
 * @param param
 * @constructor
 */
const MobilePointImg = ({param}:{param:SinceBlockType}):JSX.Element => {
  return <img className="mobile-img" src={param.img} alt={param.title} />;
}

/**
 * 음악 페이지
 * @constructor
 */
const Music = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [artists, setArtists] = useState<any>();
  const [scrollActive, setScrollActive] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const onClickArtist = (index?: string) => navigate(`/music-artist/${index}`);

  // 첫 번째 블럭
  const [sinceBlock1Img, setSinceBlock1Img] = useState<SinceBlockType>(sinceBlock1List.albumLoveCollage);
  const [sinceBlock1Fading, setSinceBlock1Fading] = useState(false);

  /**
   * 비디오 새 창 열기
   * @param url 주소
   */
  const openNewTabVideo = (url: string) => {
    window.open(url, '_blank');
  }

  /**
   * 멜론 새 창 열기
   * @param id 앨범 id
   */
  const openNewTabMelon = (id: string) => {
    window.open(melonUrlPrefix + id, '_blank');
  }

  /**
   * 목록 내 active 처리 전체 삭제
   */
  const listActiveAllRemove = () => {
    const elements:NodeListOf<Element> = document.querySelectorAll('.point');
    elements.forEach(el => el.classList.remove('active'));
  }

  /**
   * 목록 내 active 처리
   * @param key 고유값
   */
  const listActive = (key: string) => {
    const element = document.querySelector(`.point.${key}`);
    if(element !== null) {
      element.classList.add('active');
    }
  }

  /**
   * 목록에 active 처리
   * @param key 고유값
   */
  const listOnActive = (key: string) => {
    listActiveAllRemove();
    listActive(key);
  }

  /**
   * 스크롤 고정
   */
  const scrollFixed = () => {
    // 좌측 영역 고정 처리
    if (scrollY > 1500) {
      setScrollY(window.scrollY);
      setScrollActive(true);
    } else {
      setScrollY(window.scrollY);
      setScrollActive(false);
    }

    // 화면 가로값 1200 보다 작을 경우에 아래 로직 필요 없으므로 리턴 처리
    if(document.documentElement.clientWidth < 1200) {
      return;
    }

    console.log(scrollY);

    // 첫 번째 sinceBlock
    if(scrollY >= 2000 && scrollY <= 4980) {
      // 좌측 이미지 교체
      if(scrollY >= 2000 && scrollY <= 3500) {
        listOnActive('albumLoveCollage');
        if (sinceBlock1Img.img !== sinceBlock1List.albumLoveCollage.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.albumLoveCollage);
            setSinceBlock1Fading(false);
          }, 500);
        }
      } else if(scrollY >= 3500 && scrollY <= 3800) {
        listOnActive('albumHerGarden');
        if (sinceBlock1Img.img !== sinceBlock1List.albumHerGarden.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.albumHerGarden);
            setSinceBlock1Fading(false);
          }, 500);
        }
      } else if(scrollY >= 3800 && scrollY <= 4560) {
        listOnActive('albumOnChristmasDay');
        if (sinceBlock1Img.img !== sinceBlock1List.albumOnChristmasDay.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.albumOnChristmasDay);
            setSinceBlock1Fading(false);
          }, 500);
        }
      } else if(scrollY >= 4560 && scrollY <= 4950) {
        listOnActive('albumSonnet');
        if (sinceBlock1Img.img !== sinceBlock1List.albumSonnet.img) {
          setSinceBlock1Fading(true);
          setTimeout(() => {
            setSinceBlock1Img(sinceBlock1List.albumSonnet);
            setSinceBlock1Fading(false);
          }, 500);
        }
      }
    }
  }

  useEffect(() => {
    const scrollListener = () => {
      window.addEventListener('scroll', scrollFixed);
    };
    scrollListener();
    return () => {
      window.removeEventListener('scroll', scrollFixed);
    };
  });

  useEffect(() => {
    axios
      .get(`${CONFIG.API_CALL_URL()}/entertainment/artist_list.php?att=7`)
      .then((response) => {
        // console.log(response.data.Result_Data.data);
        setArtists(response.data.Result_Data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const renderArtists = () => {
    if (artists !== undefined) {
      return artists.map((item: any) => {
        return (
          <div
            style={{cursor: `${item.use_detail === 'Y' ? 'x' : ''}`}}
            key={item.idx}
            onClick={() => {
              if (item.use_detail === 'Y') onClickArtist(item.idx);
            }}
          >
            <div className="album">
              <div
                className="image-1"
                style={{
                  background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), url(${CONFIG.FILE_URL()}/storage/celeb/${
                    item.photo_thumb
                  })`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
              <div>
                <p>{item.artist_eng_name}</p>
                <p>{item.sub_intro}</p>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <div className="music">
      <div className="section-1">
        <div className="background-video">
          <video autoPlay loop muted playsInline webkit-playsInline x5-playsInline preload="auto">
            <source src={`${CONFIG.FILE_URL()}/storage/video/music.mp4`} type="video/mp4" />
          </video>
        </div>
        <div className="entertainment-link-menu show-pc">
          <div className="menu-title">
            <div>Q</div>
            <div>menu</div>
          </div>
          <div className="menu-button-wrap">
            <Link to="/documentary">
              <div className={`menu-button ${location.pathname === '/documentary' ? 'checked' : ''}`}>다큐멘터리</div>
            </Link>
            <Link to="/music">
              <div className={`menu-button ${location.pathname === '/music' ? 'checked' : ''}`}>뮤직</div>
            </Link>
          </div>
        </div>
        <div>
          <div className="overlay" />
          <PageTitle
            title="뮤직"
            subTitle="Entertainment"
            description={
              <p>
                아티스트 음반 기획, 드라마/영화 OST
                <br className="show-mobile" /> 제작 및 공연기획 등<br />폭 넓은 뮤직 비즈니스를 제공합니다
              </p>
            }
          />
          <WhiteSquare />
        </div>
      </div>

      <div className="section-2" data-aos="fade-up">
        <div className="menu-bar show-mobile">
          <Link to="/documentary">
            <div className={`${location.pathname === '/documentary' ? 'checked' : ''}`}>다큐멘터리</div>
          </Link>
          <Link to="/music">
            <div className={`${location.pathname === '/music' ? 'checked' : ''}`}>뮤직</div>
          </Link>
        </div>

        <p>OST</p>
        <div className="ost-list">
          <div className="ost">
            <img alt="image" src={ost1} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">드라마</div>
                <div className="title">질투의 화신</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost2} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">드라마</div>
                <div className="title">여우각시별</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost3} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">드라마</div>
                <div className="title">그냥 사랑하는 사이</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost4} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">드라마</div>
                <div className="title">블랙</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost5} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">드라마</div>
                <div className="title">러블리 호러블리</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost6} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">영화</div>
                <div className="title">골든슬럼버</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost7} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">영화</div>
                <div className="title">검은 사제들</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-3" data-aos="fade-up">
        <p className="section-title">Artist</p>
        <div>{renderArtists()}</div>
      </div>

      <div className="section-4" data-aos="fade-up">
        <p className="section-title">Albums</p>
        <div className="albums">
          <div className="album">
            <img alt="image" src={album1} />
            <div className="info">
              <div className="info-1">701 A-side</div>
              <div className="info-2">안녕 바다</div>
              <div className="info-3">2018.04.17</div>
              <div className="info-4">TITLE</div>
              <div className="info-5">LOVE CALL</div>
            </div>
          </div>

          <div className="album">
            <img alt="image" src={album2} />
            <div className="info">
              <div className="info-1">701 B-side</div>
              <div className="info-2">안녕 바다</div>
              <div className="info-3">2018.11.02</div>
              <div className="info-4">TITLE</div>
              <div className="info-5">꿈의 실종</div>
            </div>
          </div>

          <div className="album">
            <img alt="image" src={album3} />
            <div className="info">
              <div className="info-1">I must be a fool</div>
              <div className="info-2">My Q</div>
              <div className="info-3">2016.11.29</div>
              <div className="info-4">TITLE</div>
              <div className="info-5">I must be a fool</div>
            </div>
          </div>

          <div className="album">
            <img alt="image" src={album4} />
            <div className="info">
              <div className="info-1">F/W 2015 Issue No.2</div>
              <div className="info-2">My Q</div>
              <div className="info-3">2015.12.8</div>
              <div className="info-4">TITLE</div>
              <div className="info-5">Christmas Love</div>
            </div>
          </div>

          <div className="album">
            <img alt="image" src={album5} />
            <div className="info">
              <div className="info-1">e v e r Y d a y</div>
              <div className="info-2">My Q</div>
              <div className="info-3">2016.10.17</div>
              <div className="info-4">TITLE</div>
              <div className="info-5">e v e r Y d a y</div>
            </div>
          </div>
        </div>
      </div>

      {/* Discography 섹션 */}
      <div className="section-5" data-aos="fade-up">
        <p className="section-title">Discography</p>
        <div className="discography-contents">
          <div className="content">
            <div className="title">
              <div>
                <i />
                <p>Elli K</p>
              </div>
            </div>
            <div className="history-list">
              <div className={`year ${scrollActive ? 'fixed' : ''}`}>
                <div>
                  <img className={`${sinceBlock1Fading ? 'hidden' : ''}`}
                       src={sinceBlock1Img.img}
                       alt={sinceBlock1Img.title} />
                </div>
              </div>
              <div className="list">
                <ul>
                  {/* 2020년 */}
                  <li>
                    <p className="year">2020년</p>
                    <div>
                      <div className="itemGroup">
                        <div>
                          <ul>
                            <li className="point albumLoveCollage"
                                onClick={() => openNewTabMelon(sinceBlock1List.albumLoveCollage.albumId)}>
                              01월 [Love Collage] 발매
                              <i className="imgIcon" />
                              <MobilePointImg param={sinceBlock1List.albumLoveCollage} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* 2019년 */}
                  <li>
                    <p className="year">2019년</p>
                    <div>
                      <div className="itemGroup">
                        <div>
                          <ul>
                            <li>
                              08월 OCN 오리지널 &lt;왓쳐&gt; OST 참여
                              {/* <i className="imgIcon" /> */}
                            </li>
                            <li>09월 [Dawn] 발매</li>
                            <li>12월 [My Everything] 발매</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* 2018년 */}
                  <li>
                    <p className="year">2018년</p>
                    <div>
                      <div className="itemGroup">
                        <div>
                          <ul>
                            <li>
                              02월 영화 &lt;골든슬럼버&gt; OST 참여
                              {/* <i className="imgIcon" /> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* 2016년 */}
                  <li>
                    <p className="year">2016년</p>
                    <div>
                      <div className="itemGroup">
                        <div>
                          <ul>
                            <li>02월 [그녀의 정원 part 02] 발매</li>
                            <li>
                              03월 tvN 드라마 &lt;피리부는 사나이&gt; OST 참여
                              {/* <i className="imgIcon" /> */}
                            </li>
                            <li>05월 [그녀의 정원 part 03] 발매</li>
                            <li className="point albumHerGarden"
                                onClick={() => openNewTabMelon(sinceBlock1List.albumHerGarden.albumId)}>
                              06월 [그녀의 정원 Complete Album] 발매
                              <i className="imgIcon" />
                              <MobilePointImg param={sinceBlock1List.albumHerGarden} />
                            </li>
                            <li>
                              06월 SBS 수목드라마 &lt;질투의 화신&gt; OST 참여
                              {/* <i className="imgIcon" /> */}
                            </li>
                            <li className="point albumOnChristmasDay"
                                onClick={() => openNewTabMelon(sinceBlock1List.albumOnChristmasDay.albumId)}>
                              12월 [On Christmas Day] 발매
                              <i className="imgIcon" />
                              <MobilePointImg param={sinceBlock1List.albumOnChristmasDay} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* 2015년 */}
                  <li>
                    <p className="year">2015년</p>
                    <div>
                      <div className="itemGroup">
                        <div>
                          <ul>
                            <li>03월 영화 &lt;스물&gt; OST 음악 프로듀서</li>
                            <li>11월 [그녀의 정원 part 01] 발매</li>
                            <li>12월 영화 &lt;도리화가&gt; OST 참여 및 음악 프로듀서</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* 2014년 */}
                  <li>
                    <p className="year">2014년</p>
                    <div>
                      <div className="itemGroup">
                        <div>
                          <ul>
                            <li>07월 영화 &lt;명량&gt; OST 음악프로듀서</li>
                            <li>
                              12월 한중 합작영화 &lt;연애의 발동&gt; OST 참여
                              {/* <i className="imgIcon" /> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* 2010년 */}
                  <li>
                    <p className="year">2010년</p>
                    <div>
                      <div className="itemGroup">
                        <div>
                          <ul>
                            <li>
                              02월 MBC 월화 드라마 &lt;파스타&gt; OST 참여
                              {/* <i className="imgIcon" /> */}
                            </li>
                            <li>
                              05월 KBS 일일 드라마 &lt;바람불어 좋은 날&gt; OST 참여
                              {/* <i className="imgIcon" /> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* 2008년 */}
                  <li>
                    <p className="year">2008년</p>
                    <div>
                      <div className="itemGroup">
                        <div>
                          <ul>
                            <li className="point albumSonnet"
                                onClick={() => openNewTabMelon(sinceBlock1List.albumSonnet.albumId)}>
                              05월 정규 1집 [Sonnet]
                              <i className="imgIcon" />
                              <MobilePointImg param={sinceBlock1List.albumSonnet} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="video-contents">
              <div>
                <p>Music Video</p>
                <iframe width="1157" height="600"
                        src="https://www.youtube.com/embed/VxAN19LTgRw?si=qqejCx_seewambX3"
                        title="[Official M/V] Sonnet - Elli K (엘리케이)"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
                <ul className="thumb-list">
                  <li onClick={() => openNewTabVideo("https://www.youtube.com/watch?v=EQecat5cw48")}>
                    <img src={ellikVideoThumb1} alt="[Official M/V] Dawn – Elli K (엘리케이)" />
                  </li>
                  <li
                    onClick={() => openNewTabVideo("https://www.youtube.com/watch?v=lncPHC5-tVA&list=PLHJNqv1nvXyVKSCJESe-pJPMgyUwDnmzs&index=3")}>
                    <img src={ellikVideoThumb2} alt="[Official M/V] The Shadow of Your Smile – Elli K (엘리케이)" />
                  </li>
                  <li onClick={() => openNewTabVideo("https://www.youtube.com/watch?v=VXLhDQ4W-NA")}>
                    <img src={ellikVideoThumb3} alt="[Official M/V] New Life – Elli K (엘리케이)" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="title">
              <div>
                <i />
                <p>KIM TAE SEONG</p>
              </div>
            </div>
            <div className="banner kimtaeseong">
              <div className="box">
                KIM TAE-SEONG & MONOPOLE
              </div>
              <p>FILM MUSIC COMPOSER, 음악으로 연출하는 사람</p>
              <div className="link">
                FILMOGRAPHY
                <i onClick={() => window.open('https://kimtaeseong-studio.com/filmography', '_blank')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Music;